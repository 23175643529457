<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      code: null,
    };
  },
  mounted() {
    let that = this;
    this.code = this.$route.query.code;
    let state = this.$route.query.state;
    // console.log(this.code);
    if (this.code) {
      window.parent.postMessage(
        {
          cmd: "returnInfo",
          params: {
            code: that.code,
          },
        },
        "*"
      );
    }
  },
  methods: {
    refresh() {
      this.reload();
    },
  },
};
</script>
